/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createIceRinkAgenda = /* GraphQL */ `
  mutation CreateIceRinkAgenda(
    $input: CreateIceRinkAgendaInput!
    $condition: ModelIceRinkAgendaConditionInput
  ) {
    createIceRinkAgenda(input: $input, condition: $condition) {
      uniqueKey
      date
      time
      customerfalabellaID
      customer {
        id
        firstname
        lastname
        email
        phone
        fragancy
        IceRinkAgenda {
          items {
            uniqueKey
            date
            time
            customerfalabellaID
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateIceRinkAgenda = /* GraphQL */ `
  mutation UpdateIceRinkAgenda(
    $input: UpdateIceRinkAgendaInput!
    $condition: ModelIceRinkAgendaConditionInput
  ) {
    updateIceRinkAgenda(input: $input, condition: $condition) {
      uniqueKey
      date
      time
      customerfalabellaID
      customer {
        id
        firstname
        lastname
        email
        phone
        fragancy
        IceRinkAgenda {
          items {
            uniqueKey
            date
            time
            customerfalabellaID
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteIceRinkAgenda = /* GraphQL */ `
  mutation DeleteIceRinkAgenda(
    $input: DeleteIceRinkAgendaInput!
    $condition: ModelIceRinkAgendaConditionInput
  ) {
    deleteIceRinkAgenda(input: $input, condition: $condition) {
      uniqueKey
      date
      time
      customerfalabellaID
      customer {
        id
        firstname
        lastname
        email
        phone
        fragancy
        IceRinkAgenda {
          items {
            uniqueKey
            date
            time
            customerfalabellaID
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCustomerFalabella = /* GraphQL */ `
  mutation CreateCustomerFalabella(
    $input: CreateCustomerFalabellaInput!
    $condition: ModelCustomerFalabellaConditionInput
  ) {
    createCustomerFalabella(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      phone
      fragancy
      IceRinkAgenda {
        items {
          uniqueKey
          date
          time
          customerfalabellaID
          customer {
            id
            firstname
            lastname
            email
            phone
            fragancy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomerFalabella = /* GraphQL */ `
  mutation UpdateCustomerFalabella(
    $input: UpdateCustomerFalabellaInput!
    $condition: ModelCustomerFalabellaConditionInput
  ) {
    updateCustomerFalabella(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      phone
      fragancy
      IceRinkAgenda {
        items {
          uniqueKey
          date
          time
          customerfalabellaID
          customer {
            id
            firstname
            lastname
            email
            phone
            fragancy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomerFalabella = /* GraphQL */ `
  mutation DeleteCustomerFalabella(
    $input: DeleteCustomerFalabellaInput!
    $condition: ModelCustomerFalabellaConditionInput
  ) {
    deleteCustomerFalabella(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      phone
      fragancy
      IceRinkAgenda {
        items {
          uniqueKey
          date
          time
          customerfalabellaID
          customer {
            id
            firstname
            lastname
            email
            phone
            fragancy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCustomerRipley = /* GraphQL */ `
  mutation CreateCustomerRipley(
    $input: CreateCustomerRipleyInput!
    $condition: ModelCustomerRipleyConditionInput
  ) {
    createCustomerRipley(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      phone
      fragancy
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomerRipley = /* GraphQL */ `
  mutation UpdateCustomerRipley(
    $input: UpdateCustomerRipleyInput!
    $condition: ModelCustomerRipleyConditionInput
  ) {
    updateCustomerRipley(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      phone
      fragancy
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomerRipley = /* GraphQL */ `
  mutation DeleteCustomerRipley(
    $input: DeleteCustomerRipleyInput!
    $condition: ModelCustomerRipleyConditionInput
  ) {
    deleteCustomerRipley(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      phone
      fragancy
      createdAt
      updatedAt
    }
  }
`;
export const createCustomerParis = /* GraphQL */ `
  mutation CreateCustomerParis(
    $input: CreateCustomerParisInput!
    $condition: ModelCustomerParisConditionInput
  ) {
    createCustomerParis(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      phone
      fragancy
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomerParis = /* GraphQL */ `
  mutation UpdateCustomerParis(
    $input: UpdateCustomerParisInput!
    $condition: ModelCustomerParisConditionInput
  ) {
    updateCustomerParis(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      phone
      fragancy
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomerParis = /* GraphQL */ `
  mutation DeleteCustomerParis(
    $input: DeleteCustomerParisInput!
    $condition: ModelCustomerParisConditionInput
  ) {
    deleteCustomerParis(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      phone
      fragancy
      createdAt
      updatedAt
    }
  }
`;
