import React from 'react';
import Modal from 'react-bootstrap/Modal';

const Index = ({ show, onHide }) => {
  return (
    <Modal {...{ show, onHide }} className="modal-box">
      <Modal.Header closeButton>
        <Modal.Title>Hubo un error</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h3>Algo está fallando</h3>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100"
          height="100"
          fill="#C00000"
          className="bi bi-exclamation-octagon-fill my-3"
          viewBox="0 0 16 16">
          <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
        </svg>
        <h3>Intenta nuevamente</h3>
      </Modal.Body>
    </Modal>
  );
};

export default Index;
