import userReducer from './user';
import apiReducer from './api';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  user: userReducer,
  api: apiReducer
});

export default rootReducer;
