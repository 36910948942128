import React from "react";
import { Col, Row } from "react-bootstrap";

export default function Cleanup({interval}) {
  return (
    <Row className="mb-3 px-2" >
      <Col xs="2" className="time-agenda text-center">
        {interval}
      </Col>
      <Col xs="10" className="badge-agenda rounded-pill px-4 cleanup">
        Limpieza
      </Col>
    </Row>
  );
}
