import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

const Index = ({ user, loading, show, onHide, playData, savePlay }) => {
  return (
    <Modal {...{ show, onHide }} className="modal-box">
      <Modal.Header closeButton>
        <Modal.Title>Reservar Patinaje</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Reservar para <b>{user.firstname}</b> el <b>{playData.stringDate}</b> a las{' '}
          <b>{playData.time}</b>
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={savePlay}>
          Guardar Reserva{" "}
          {loading && (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Index;
