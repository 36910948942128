import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Form from "../../components/Form";
import { resetStore, saveUser } from "../../store/actions/user";
import { withRouter } from "react-router-dom";
import { initFormData, singleSwitch, stores } from "../../const";
const EMAIL_REGEX_VALIDATION = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,3})+$/;

const Index = ({ saveUserService, resetStoreService, registeredUser, apiInfo, history }) => {
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [formData, setFormData] = useState(initFormData);
  const [switches, setSwitches] = useState(singleSwitch);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const handleOnChange = (prop) => {
    const newFormData = {
      ...formData,
      [prop.target.id]: prop.target.value,
    };
    setFormData(newFormData);
  };
  const handleSwitch = (prop) => {
    setSwitches({
      ...switches,
      [prop.target.id]: prop.target.checked,
    });
  };
  const submitForm = () => {
    saveUserService({ ...formData, store: stores.PARIS });
  };

  useEffect(() => {
    if (EMAIL_REGEX_VALIDATION.test(formData.email)) {
      setInvalidEmail(false);
    } else {
      setInvalidEmail(true);
    }
  }, [formData]);

  useEffect(() => {
    if (registeredUser) {
      setFormData(initFormData);
      setSwitches(singleSwitch);
      setShowSuccessAlert(true);
      setTimeout(() => {
        setShowSuccessAlert(false);
      }, 5000);
    }
  }, [registeredUser]);

  const backToInit = () => {
    resetStoreService();
    history.push("/");
  };
  const onCloseSuccessAlert = () => {
    setShowSuccessAlert(false);
  };

  const handleSelectFragancy = (e) => {
    setFormData({
      ...formData,
      fragancy: e.target.value,
    });
  };

  return (
    <>
      <Form
        {...{
          handleOnChange,
          handleSelectFragancy,
          formData,
          registeredUser,
          invalidEmail,
          handleSwitch,
          switches,
          submitForm,
          showSuccessAlert,
          onCloseSuccessAlert,
          backToInit,
          loadingApi: apiInfo.loading,
          store: stores.PARIS,
        }}
      />
    </>
  );
};

const mapStateToProps = (store) => ({
  registeredUser: store.user.savedUser,
  apiInfo: store.api
});

const mapDispatchToProps = (dispatch) => ({
  saveUserService: (props) => dispatch(saveUser(props)),
  resetStoreService: () => dispatch(resetStore()),
});

const connected = connect(mapStateToProps, mapDispatchToProps)(Index);
export default withRouter(connected);
