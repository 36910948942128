// import { DataStore } from "@aws-amplify/datastore";
import { API, graphqlOperation } from 'aws-amplify';
import {
  createCustomerFalabella,
  createCustomerParis,
  createCustomerRipley
} from '../graphql/mutations';

export const saveFallabelaUser = async (formData) => {
  const customer = {
    firstname: formData.firstname,
    lastname: formData.lastname,
    email: formData.email,
    phone: formData.phone,
    fragancy: formData.fragancy
  };
  const registered = await API.graphql(
    graphqlOperation(createCustomerFalabella, { input: customer })
  );
  const { data, error } = registered;
  if (error) {
    return null;
  }
  return data.createCustomerFalabella;
};

export const saveParisUser = async (formData) => {
  const customer = {
    firstname: formData.firstname,
    lastname: formData.lastname,
    email: formData.email,
    phone: formData.phone,
    fragancy: formData.fragancy
  };
  const registered = await API.graphql(graphqlOperation(createCustomerParis, { input: customer }));
  const { data, error } = registered;
  if (error) {
    return null;
  }
  return data.createCustomerParis;
};

export const saveRipleyUser = async (formData) => {
  const customer = {
    firstname: formData.firstname,
    lastname: formData.lastname,
    email: formData.email,
    phone: formData.phone,
    fragancy: formData.fragancy
  };
  const registered = await API.graphql(graphqlOperation(createCustomerRipley, { input: customer }));
  const { data, error } = registered;
  if (error) {
    return null;
  }
  return data.createCustomerRipley;
};
