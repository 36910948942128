/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getIceRinkAgenda = /* GraphQL */ `
  query GetIceRinkAgenda($uniqueKey: ID!) {
    getIceRinkAgenda(uniqueKey: $uniqueKey) {
      uniqueKey
      date
      time
      customerfalabellaID
      customer {
        id
        firstname
        lastname
        email
        phone
        fragancy
        IceRinkAgenda {
          items {
            uniqueKey
            date
            time
            customerfalabellaID
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listIceRinkAgenda = /* GraphQL */ `
  query ListIceRinkAgenda(
    $uniqueKey: ID
    $filter: ModelIceRinkAgendaFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listIceRinkAgenda(
      uniqueKey: $uniqueKey
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        uniqueKey
        date
        time
        customerfalabellaID
        customer {
          id
          firstname
          lastname
          email
          phone
          fragancy
          IceRinkAgenda {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const iceRinkAgendaByDate = /* GraphQL */ `
  query IceRinkAgendaByDate(
    $date: String!
    $sortDirection: ModelSortDirection
    $filter: ModelIceRinkAgendaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    iceRinkAgendaByDate(
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        uniqueKey
        date
        time
        customerfalabellaID
        customer {
          id
          firstname
          lastname
          email
          phone
          fragancy
          IceRinkAgenda {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const iceRinkAgendaByCustomerfalabellaID = /* GraphQL */ `
  query IceRinkAgendaByCustomerfalabellaID(
    $customerfalabellaID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelIceRinkAgendaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    iceRinkAgendaByCustomerfalabellaID(
      customerfalabellaID: $customerfalabellaID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        uniqueKey
        date
        time
        customerfalabellaID
        customer {
          id
          firstname
          lastname
          email
          phone
          fragancy
          IceRinkAgenda {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomerFalabella = /* GraphQL */ `
  query GetCustomerFalabella($id: ID!) {
    getCustomerFalabella(id: $id) {
      id
      firstname
      lastname
      email
      phone
      fragancy
      IceRinkAgenda {
        items {
          uniqueKey
          date
          time
          customerfalabellaID
          customer {
            id
            firstname
            lastname
            email
            phone
            fragancy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCustomerFalabellas = /* GraphQL */ `
  query ListCustomerFalabellas(
    $filter: ModelCustomerFalabellaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerFalabellas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstname
        lastname
        email
        phone
        fragancy
        IceRinkAgenda {
          items {
            uniqueKey
            date
            time
            customerfalabellaID
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomerRipley = /* GraphQL */ `
  query GetCustomerRipley($id: ID!) {
    getCustomerRipley(id: $id) {
      id
      firstname
      lastname
      email
      phone
      fragancy
      createdAt
      updatedAt
    }
  }
`;
export const listCustomerRipleys = /* GraphQL */ `
  query ListCustomerRipleys(
    $filter: ModelCustomerRipleyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerRipleys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstname
        lastname
        email
        phone
        fragancy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomerParis = /* GraphQL */ `
  query GetCustomerParis($id: ID!) {
    getCustomerParis(id: $id) {
      id
      firstname
      lastname
      email
      phone
      fragancy
      createdAt
      updatedAt
    }
  }
`;
export const listCustomerParises = /* GraphQL */ `
  query ListCustomerParises(
    $filter: ModelCustomerParisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerParises(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstname
        lastname
        email
        phone
        fragancy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
