import React, { forwardRef, useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, set, add, compareAsc, compareDesc } from 'date-fns';
import es from 'date-fns/locale/es';
import Play from '../../components/Agenda/play';
import Cleanup from '../../components/Agenda/cleanup';
import Modal from '../../components/Modal';
import DeleteModal from '../../components/DeleteModal';
import Alert from 'react-bootstrap/Alert';
import { connect } from 'react-redux';
import { savePlay, resetStore } from '../../store/actions/user';
import { withRouter } from 'react-router-dom';
import { getDailyAgenda, deletePlay } from '../../services/play';
import { apiFetching, apiOK, apiError } from '../../store/actions/api';

registerLocale('es', es);

const INIT_HOUR = '10';
const END_HOUR = '20';
const PERIOD_MINUTES = 10;
const USERS_FOR_PERIOD = 8;
const UNAVAILABLE_MINUTES = 5;
const PLAY_INTERVAL = 'PLAY';
const CLEANUP_INTERVAL = 'CLEANUP';

const START_DATE = new Date('2022-12-13');
const END_DATE = new Date('2022-12-26T10:00:00');
const EXCLUDE_DATES = [new Date('2022-12-25T10:00:00')];

const daysAvailables = (date) => {
  return date >= START_DATE && date < END_DATE;
};

const defaultStartTime = {
  hours: INIT_HOUR,
  minutes: 0,
  seconds: 0,
  milliseconds: 0
};
const defaultEndTime = {
  hours: END_HOUR,
  minutes: 0,
  seconds: 0,
  milliseconds: 0
};

const setIntervals = (date, agenda = null) => {
  let startDate = new Date(date);
  let endDate = set(new Date(date), defaultEndTime);
  let intervals = [];
  const reservations = agenda.map((r) => {
    const [date, slot, time] = r.uniqueKey.split('*');
    return { uniqueKey: r.uniqueKey, date, slot, time, ...(r.customer || {}) };
  });
  while (compareAsc(startDate, endDate)) {
    //create interval
    const time = format(startDate, 'H:mm');
    let users = new Array(USERS_FOR_PERIOD).fill(null);
    const timeReservations = reservations.filter((r) => r.time === time);
    users = users.map((u, i) => {
      const reservation = timeReservations.find((tr) => tr.slot === (i + 1).toString());
      if (reservation) return reservation;
      return null;
    });
    let interval = {
      interval: time,
      type: PLAY_INTERVAL,
      users
    };
    //add cleanup interval
    startDate = add(startDate, { minutes: PERIOD_MINUTES });
    let cleanupInterval = {
      interval: format(startDate, 'H:mm'),
      type: CLEANUP_INTERVAL
    };
    startDate = add(startDate, { minutes: UNAVAILABLE_MINUTES });
    //add play minutes
    intervals.push(interval, cleanupInterval);
  }
  return intervals;
};

const Index = ({
  history,
  loadingApi,
  registeredUser,
  registeredPlay,
  savePlayService,
  resetStoreService,
  apiFetchingService,
  apiOKService,
  apiErrorService
}) => {
  const initDate = set(new Date(), defaultStartTime);
  const [playData, setPlayData] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [day, setDay] = useState({
    date: initDate,
    stringDate: format(initDate, 'MM/dd/yyyy'),
    name: format(initDate, 'EEEE', { locale: es })
  });
  const [schedule, setSchedule] = useState([]);
  const [deleting, setDeleting] = useState(null);
  const onChangeDay = (date) => {
    const newDate = set(new Date(date), defaultStartTime);
    setDay({
      date: newDate,
      stringDate: format(newDate, 'MM/dd/yyyy'),
      name: format(newDate, 'EEEE', { locale: es })
    });
  };
  const fetchAgenda = async () => {
    apiFetchingService();
    const agenda = await getDailyAgenda(day.stringDate);
    if (agenda.error) {
      apiErrorService();
    } else {
      apiOKService();
      setSchedule(setIntervals(day.date, agenda));
    }
  };
  useEffect(() => {
    fetchAgenda();
  }, [day]);

  const onClickAvailablePeriod = (e) => {
    if (registeredUser) {
      setPlayData({
        date: format(day.date, 'MM/dd/yyyy'),
        stringDate: format(day.date, 'dd/MM/yyyy'),
        time: e.target.dataset.interval,
        slot: e.target.dataset.slot
      });
    }
  };
  useEffect(() => {
    if (playData) setShowConfirmModal(true);
    else setShowConfirmModal(false);
  }, [playData]);

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <Button className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-arrow-down-short"
        viewBox="0 0 16 16">
        <path
          fillRule="evenodd"
          d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"
        />
      </svg>{' '}
    </Button>
  ));
  CustomInput.displayName = 'CustomInput';

  const onSavePlay = () => {
    const { date, slot, time } = playData;
    const uniqueKey = [date, slot, time].join('*');
    savePlayService({ ...playData, uniqueKey, registeredUser });
  };

  const onDeleteAgenda = (uniqueKey, interval) => {
    const reservationToDelete = schedule
      .find((block) => block.interval === interval)
      .users?.find((user) => user?.uniqueKey === uniqueKey);
    setDeleting({
      ...reservationToDelete,
      stringDate: format(day.date, 'dd/MM/yyyy')
    });
  };

  const onDeletePlay = async () => {
    apiFetchingService();
    const deleted = await deletePlay(deleting.uniqueKey);
    if (deleted.error) {
      apiErrorService();
      setDeleting(null);
    } else {
      apiOKService();
      fetchAgenda();
      setDeleting(null);
    }
  };

  const backToRegister = () => {
    resetStoreService();
    history.push('/falabella');
  };

  useEffect(() => {
    if (registeredPlay) {
      setShowConfirmModal(false);
      resetStoreService();
      fetchAgenda();
    }
  }, [registeredPlay]);
  return (
    <>
      {!!registeredUser && (
        <Alert variant={'success'} className="fixed-top">
          Reserva un periodo de patinaje para <b>{registeredUser['firstname']}</b>
        </Alert>
      )}
      <Modal
        show={showConfirmModal}
        user={registeredUser || {}}
        playData={playData || {}}
        loading={loadingApi}
        date={day.date}
        onHide={() => setShowConfirmModal(false)}
        savePlay={onSavePlay}
      />
      <DeleteModal
        show={!!deleting}
        loading={loadingApi}
        data={deleting || {}}
        onHide={() => setDeleting(false)}
        deletePlay={onDeletePlay}
      />

      <Container className="mt-5">
        <Row className={`${!!registeredUser && 'pt-4'}`}>
          <Col xs="6" className={`${!!registeredUser && 'mt-3'}`}>
            <h2>Agenda diaria</h2>
          </Col>
          <Col xs="6" className="text-end">
            <Button onClick={backToRegister}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-left"
                viewBox="0 0 16 16">
                <path
                  fillRule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                />
              </svg>{' '}
              Volver a Registro
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xs="12">
            <div className="agenda py-4 px-3 rounded">
              <Row className="mb-3">
                <Col xs="2" className="d-flex align-items-center justify-content-center">
                  <p className="mb-0">{day.name}</p>
                </Col>
                <Col xs={{ span: 3, offset: 7 }} className="rounded text-end">
                  <DatePicker
                    wrapperClassName="w-auto "
                    locale={'es'}
                    selected={day.date}
                    onChange={onChangeDay}
                    dateFormat="d MMM"
                    customInput={<CustomInput />}
                    filterDate={daysAvailables} // TODO: quitar comment
                    excludeDates={EXCLUDE_DATES}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <hr />
                </Col>
              </Row>
              {schedule.map((interval) =>
                interval.type === PLAY_INTERVAL ? (
                  <Play
                    key={`${interval.interval}-${PLAY_INTERVAL}`}
                    onDeleteAgenda={onDeleteAgenda}
                    onClick={onClickAvailablePeriod}
                    {...interval}
                  />
                ) : (
                  <Cleanup key={`${interval.interval}-${CLEANUP_INTERVAL}`} {...interval} />
                )
              )}
              {/* <Row className="mb-3 px-2">
                <Col xs="2" className="time-agenda text-center">
                  10:00
                </Col>
                <Col xs="10" className="badge-agenda rounded-pill px-4">
                  test nombre de alguien
                </Col>
              </Row>
              <Row className="mb-3 px-2">
                <Col xs="2" className="time-agenda text-center">
                  10:00
                </Col>
                <Col xs="10" className="badge-agenda rounded-pill empty">
                  test nombre de alguien
                </Col>
              </Row> */}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (store) => ({
  registeredUser: store.user.savedUser,
  registeredPlay: store.user.savedPlay,
  loadingApi: store.api.loading
});

const mapDispatchToProps = (dispatch) => ({
  savePlayService: (props) => dispatch(savePlay(props)),
  resetStoreService: () => dispatch(resetStore()),
  apiFetchingService: () => dispatch(apiFetching()),
  apiOKService: () => dispatch(apiOK()),
  apiErrorService: () => dispatch(apiError())
});

// export default connect(mapStateToProps, mapDispatchToProps)(Index);

const connected = connect(mapStateToProps, mapDispatchToProps)(Index);
export default withRouter(connected);
