import { POST_USER, POST_PLAY, RESET_STORE } from '../const';
import { stores } from '../../const';
import { saveFallabelaUser, saveParisUser, saveRipleyUser } from '../../services/user';
import { apiError, apiFetching, apiOK } from './api';
import { saveIcerRinkAgenda } from '../../services/play';

const _saveUser = (payload = {}) => {
  return { type: POST_USER, payload };
};

const _savePlay = (payload = {}) => {
  return { type: POST_PLAY, payload };
};

const _resetStore = () => {
  return { type: RESET_STORE };
};

export const saveUser = ({ store, ...formData }) => {
  return async (dispatch) => {
    try {
      dispatch(apiFetching());
      let registeredUser;
      switch (store) {
        case stores.FALABELLA:
          registeredUser = await saveFallabelaUser(formData);
          break;
        case stores.PARIS:
          registeredUser = await saveParisUser(formData);
          break;
        case stores.RIPLEY:
          registeredUser = await saveRipleyUser(formData);
          break;
        default:
          break;
      }
      dispatch(apiOK());
      dispatch(_saveUser({ ...registeredUser, registered: true }));
    } catch (error) {
      // dispatch(_saveUser({ error: "ok" }));
      dispatch(apiError());
    }
  };
};

export const savePlay = (playData) => {
  return async (dispatch) => {
    try {
      dispatch(apiFetching());
      const registeredPlay = await saveIcerRinkAgenda(playData);
      dispatch(apiOK());
      dispatch(_savePlay({ ...registeredPlay, registered: 'ok' }));
    } catch (error) {
      dispatch(apiError());
    }
  };
};

export const resetStore = () => {
  return async (dispatch) => {
    dispatch(_resetStore());
  };
};
