import React from "react";
import { Col, Row } from "react-bootstrap";

export default function Play({
  interval,
  users,
  onClick,
  onDeleteAgenda,
  ...rest
}) {
  return (
    <>
      {users.map((user, i) => {
        return (
          <Row className="mb-3 px-2" key={`no-independent-key-${i}`}>
            <Col xs="2" className="time-agenda text-center">
              {interval}
            </Col>
            <Col
              xs="10"
              className={`badge-agenda rounded-pill px-4 ${
                user ? "filled-place" : "empty-place"
              }`}
              {...(!user ? { onClick } : {})}
              data-interval={interval}
              data-slot={i + 1}
            >
              <Row>
                <Col xs="10">
                  {user ? `${user.firstname} ${user.lastname}` : ""}
                </Col>
                {!!user && (
                  <Col
                    xs="2"
                    className="d-flex flex-column justify-content-center"
                    onClick={() => onDeleteAgenda(user.uniqueKey, interval)}
                    data-unique-key={user?.uniqueKey}
                    data-interval={interval}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-trash-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                    </svg>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        );
      })}
    </>
  );
}
