import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

const Index = ({ show, loading, data, onHide, deletePlay }) => {
  return (
    <Modal {...{ show, onHide }} className="modal-box">
      <Modal.Header closeButton>
        <Modal.Title>Reservar Patinaje</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Seguro que deseas borrar la reserva de <b>{`${data.firstname} ${data.lastname}`}</b>
          {` para el día`} <b>{` ${data.stringDate}`}</b> {` a las `} <b>{`${data.time} hrs.?`}</b>
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={deletePlay}>
          Borrar Reserva{' '}
          {loading && (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Index;
