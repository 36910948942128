import { API, graphqlOperation } from 'aws-amplify';
import { createIceRinkAgenda, deleteIceRinkAgenda } from '../graphql/mutations';
import { iceRinkAgendaByDate } from '../graphql/queries';
export const saveIcerRinkAgenda = async ({ date, time, uniqueKey, registeredUser }) => {
  const agenda = {
    uniqueKey,
    date,
    time,
    customerfalabellaID: registeredUser.id
  };
  const registeredAgenda = await API.graphql(
    graphqlOperation(createIceRinkAgenda, { input: agenda })
  );
  const { data, error } = registeredAgenda;
  if (error) {
    return { error: true };
  }
  return data.createIceRinkAgenda;
};

export const getDailyAgenda = async (day) => {
  try {
    const agenda = await API.graphql({
      query: iceRinkAgendaByDate,
      variables: { date: day }
    });
    const { data, error } = agenda;
    if (error) {
      return { error: true };
    }
    return data.iceRinkAgendaByDate.items;
  } catch (error) {
    return {error: true}
  }
};

export const deletePlay = async (uniqueKey) => {
  try {
    const deletedPlay = await API.graphql({
      query: deleteIceRinkAgenda,
      variables: { input: { uniqueKey } }
    });
    return deletedPlay;
  } catch (error) {
    return { error: true };
  }
};
