import { ERROR_API, FETCHING_API, OK_API, RESET_API } from '../const';

export const apiError = () => {
  return async (dispatch) => dispatch({type: ERROR_API});
};

export const apiFetching = () => {
  return async (dispatch) => dispatch({type: FETCHING_API});
};

export const apiOK = () => {
  return async (dispatch) => dispatch({type: OK_API});
};

export const apiReset = () => {
  return async (dispatch) => dispatch({type: RESET_API});
};
