import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import InitPage from './InitPage';
import Falabella from './falabella';
import Ripley from './ripley';
import Paris from './paris';
import Agenda from './agenda';
import ErrorModal from '../components/ErrorModal';
import { Route, Switch } from 'react-router-dom';
import { apiReset } from '../store/actions/api';

const Index = ({ apiInfo, resetApiInfoService }) => {
  const [showError, setShowError] = useState(false);
  useEffect(() => {
    if (apiInfo.error) {
      setShowError(true);
    }
  }, [apiInfo]);

  const dismissError = () => {
    setShowError(false);
    resetApiInfoService();
  };
  return (
    <>
      {showError && <ErrorModal show={showError} onHide={dismissError} />}
      <Switch>
        <Route exact path="/" component={InitPage} />
        <Route exact path="/falabella" component={Falabella} />
        <Route exact path="/paris" component={Paris} />
        <Route exact path="/ripley" component={Ripley} />
        <Route exact path="/agenda" component={Agenda} />
      </Switch>
    </>
  );
};

const mapStateToProps = (store) => ({
  apiInfo: store.api
});

const mapDispatchToProps = (dispatch) => ({
  resetApiInfoService: () => dispatch(apiReset())
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
