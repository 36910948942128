import initState from '../initState';
import { ERROR_API, FETCHING_API, OK_API, RESET_API } from '../const';

export default (state = initState.api, action) => {
  switch (action.type) {
    case ERROR_API:
      return { ok: false, loading: false, error: true };
    case FETCHING_API:
      return { ok: false, loading: true, error: false };
    case OK_API:
      return { ok: true, loading: false, error: false };
    case RESET_API:
      return { ok: false, loading: false, error: false };
    default:
      return state;
  }
};
