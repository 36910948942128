export const initFormData = {
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  fragancy: "",
};

export const initSwitches = {
  "terms-switch": false,
  "use-data-switch": false,
  "responsability-switch": false,
};

export const singleSwitch = {
  "use-data-switch": false,
};

export const stores = {
  FALABELLA: "FALABELLA",
  PARIS: "PARIS",
  RIPLEY: "RIPLEY",
};

export const pdfFiles = {
  TERMS: "https://docslegalespuig202212nav.s3.amazonaws.com/09.12.22+REGLAMENTO+DE+USO+DE+LA+PISTA+DE+PATINAJE+VF_.pdf",
  ALLOWDATA: "https://docslegalespuig202212nav.s3.amazonaws.com/POL%C3%8DTICA+DE+PRIVACIDAD+-+USO+DE+DATOS.pdf",
  RESPONSABILITY: "https://docslegalespuig202212nav.s3.amazonaws.com/CONSENTIMIENTO+CLIENTE+PARA+USO+DE+PISTA+DE+PATINAJE.pdf",
};
