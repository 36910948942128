import initState from "../initState";
import { POST_USER, POST_PLAY, RESET_STORE } from "../const";

export default (state = initState.user, action) => {
  switch (action.type) {
    case POST_USER:
      return {
        ...state,
        savedUser: action.payload,
      };
    case POST_PLAY:
      return {
        ...state,
        savedPlay: action.payload,
      };
    case RESET_STORE:
      return {
        ...initState.user,
      };
    default:
      return state;
  }
};
