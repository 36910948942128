import React from "react";
import {
  Alert,
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Button,
  Spinner,
} from "react-bootstrap";
import { pdfFiles, stores } from "../../const";

export default function Index({
  handleOnChange,
  handleSelectFragancy,
  formData,
  registeredUser,
  invalidEmail,
  handleSwitch,
  switches,
  submitForm,
  store,
  backToInit,
  goToAgenda,
  showSuccessAlert,
  loadingApi,
  onCloseSuccessAlert,
}) {
  return (
    <>
      {!(store === stores.FALABELLA) && (
        <Alert
          className="fixed-top"
          variant="success"
          show={showSuccessAlert}
          onClose={onCloseSuccessAlert}
          dismissible
        >
          <Alert.Heading>
            Registro de <b>{registeredUser?.firstname}</b> exitoso
          </Alert.Heading>
        </Alert>
      )}
      <Container className="mt-5">
        <Row>
          <Col xs="6">
            <h2 className="mb-0">Registro Cliente</h2>
            <p>{store}</p>
          </Col>
          {store !== stores.FALABELLA &&
            <Col xs="6" className="text-end">
            <Button onClick={backToInit}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                />
              </svg>{" "}
              Volver a inicio
            </Button>
          </Col>}
        </Row>
        <Row>
          <Col xs="12">
            <Form.Label htmlFor="firstname">Primer Nombre</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                id="firstname"
                aria-describedby="basic-addon3"
                onChange={handleOnChange}
                value={formData.firstname}
              />
            </InputGroup>
          </Col>
          <Col xs="12">
            <Form.Label htmlFor="lastname">Primer Apellido</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                id="lastname"
                aria-describedby="basic-addon3"
                onChange={handleOnChange}
                value={formData.lastname}
              />
            </InputGroup>
          </Col>
          <Col xs="12">
            <Form.Label htmlFor="email">Correo electrónico:</Form.Label>
            <InputGroup className="mb-3" hasValidation>
              <Form.Control
                id="email"
                type="email"
                aria-describedby="basic-addon3"
                onChange={handleOnChange}
                value={formData["email"]}
                required
                isInvalid={invalidEmail && !!formData["email"].length}
              />
              <Form.Control.Feedback type="invalid">
                Ingresar Correo Electrónico
              </Form.Control.Feedback>
              <Form.Control.Feedback type="valid">
                Looks good!
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
          <Col xs="12">
            <Form.Label htmlFor="phone">Teléfono contacto</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text>+56 9</InputGroup.Text>

              <Form.Control
                id="phone"
                aria-describedby="basic-addon3"
                onChange={handleOnChange}
                value={formData["phone"]}
              />
            </InputGroup>
          </Col>
          <Col xs="12">
            <Form.Label htmlFor="fragancy">Fragancia Favorita CH</Form.Label>
            <Form.Control
              value={formData.fragancy}
              className="mb-3"
              as="select"
              custom="true"
              onChange={handleSelectFragancy}
            >
              {!formData.fragancy && <option value="">Seleccione una fragancia</option> }
              <option>212</option>
              <option>Bad Boy</option>
              <option>CH</option>
              <option>Good Girl</option>
              <option>Herrera Confidential</option>
            </Form.Control>
          </Col>
          <Col xs="12">
            <Form className="mb-3">
              {!!(store === stores.FALABELLA) && (
                <Form.Check
                  className="mb-3"
                  onChange={handleSwitch}
                  type="switch"
                  checked={switches["terms-switch"]}
                  id="terms-switch"
                  label={
                    <>
                      Al dar clic en este botón acepto los{" "}
                      <a href={pdfFiles.TERMS} target="_blank" rel="noreferrer">
                        {" "}
                        términos y condiciones
                      </a>{" "}
                      de uso de la pista de patinaje.
                    </>
                  }
                />
              )}
              <Form.Check
                className="mb-3"
                onChange={handleSwitch}
                type="switch"
                checked={switches["use-data-switch"]}
                id="use-data-switch"
                label={
                  <>
                    Al dar clic en este botón acepto que CH{" "}
                    <a
                      href={pdfFiles.ALLOWDATA}
                      target="_blank"
                      rel="noreferrer"
                    >
                      utilice mis datos
                    </a>{" "}
                    para contactarme con posterioridad.
                  </>
                }
              />
              {!!(store === stores.FALABELLA) && (
                <Form.Check
                  onChange={handleSwitch}
                  type="switch"
                  id="responsability-switch"
                  checked={switches["responsability-switch"]}
                  label={
                    <>
                      Al dar clic en este botón{" "}
                      <a
                        href={pdfFiles.RESPONSABILITY}
                        target="_blank"
                        rel="noreferrer"
                      >
                        libero de responsabilidad{" "}
                      </a>
                      a Falabella Retail S.A y Puig Distribuidora LTDA. en caso
                      de accidente.
                    </>
                  }
                />
              )}
            </Form>
          </Col>
          <Col xs="6">
            <Button
              onClick={submitForm}
              disabled={
                // TODO remover comment
                Object.keys(switches).reduce(
                  (acc, curr) => !switches[curr] || acc,
                  false
                ) ||
                !formData.firstname ||
                !!invalidEmail ||
                !formData.email.length ||
                !formData.fragancy.length
              }
            >
              Registrar{" "}
              {loadingApi && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </Col>
          {store === stores.FALABELLA && (
            <Col xs="6" className="text-end">
              <Button onClick={goToAgenda}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-calendar-date"
                  viewBox="0 0 16 16"
                >
                  <path d="M6.445 11.688V6.354h-.633A12.6 12.6 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z" />
                  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                </svg>{" "}
                Ir a Agenda
              </Button>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
}
